import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { IconCheckGreen } from "../Icons/Icon-check-green/IconCheckGreen";
import { IconInfo } from "../Icons/Icon-info/IconInfo ";
// import { IconArrowRight } from "../Icons/Icon-arrow-right/IconArrowRight";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  nextComponent,
  backComponent,
  setFormComponent,
  // clearFormData,
} from "../../utils/nextComponent";

export const Phone = ({ block, props }) => {
  const [t] = useTranslation("global");
  const [messageLoading, setMessageLoading] = useState(true);
  const [validPhone, setValidPhone] = useState("divTextFormChat");
  const [phone_budget, setPhone] = useState("");
  const myRef2 = useRef(null);

  const block2 = {
    _uid: "BUY6Drn1e4",
    component: "phone_budget",
    headline: "phone_budget",
    conditional: false,
  };

  useEffect(() => {
    if (block.conditional) {
      props.setShowWriting(true);
      setTimeout(() => {
        setMessageLoading(false);
        props.setShowWriting(false);
        executeScroll();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (validPhone === "divTextFormChatGreen") {
      setFormComponent(props, block2, { phone_budget });
      if (props.typeArray === 0) {
        nextComponent(props, block);
      }
      if (props.typeArray === 1) {
        nextComponent(props, block, 1);
      }
    }
    if (validPhone === "divTextFormChatRed") {
      if (props.typeArray === 0) {
        backComponent(props, block);
      }
      if (props.typeArray === 1) {
        backComponent(props, block, 1);
      }
    }
  }, [validPhone]);

  const handleChangePrefix = (e) => {
    setValidPhone("divTextFormChatRed");
    let prefix = e.slice(0, 2);
    setPhone(e);
    if (e.length >= 3) {
      if (e.length >= 11) {
        setValidPhone("divTextFormChatGreen");
      } else if (e.length >= 10 && prefix !== "34") {
        setValidPhone("divTextFormChatGreen");
      } else {
        setValidPhone("divTextFormChatRed");
        backComponent(props, block);
        // props.arrayComponent.forEach((obj) => {
        //   if (obj.component === "phone_budget") {
        //     clearFormData(props, block, { phone_budget: obj });
        //   }
        // });
        // clearFormData(props, block, { phone_budget });
      }
    }
  };

  const executeScroll = () => {
    setTimeout(() => {
      myRef2?.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
 
  const defaultPrefix =
  window.navigator.language.slice(0, 2) === "en"
    ? window.navigator.language.slice(3, 5).toLowerCase() ||
    "us"
    : window.navigator.language.slice(0, 2) === "es"
      ? window.navigator.language.slice(3, 5).toLowerCase() ||
      "es"
      : window.navigator.language.slice(0, 2) === "de"
        ? window.navigator.language.slice(3, 5).toLowerCase() ||
        "de"
        : window.navigator.language.slice(0, 2)
  return (
    <>
      {messageLoading === true ? (
        <MessageLoading />
      ) : (
        <Form.Group id="nombre" className="">
          {/* {validEmail === false && ( */}
          <div className="divGenericLabelFormChatWelcome" ref={myRef2}>
            {/* <Form.Label className="generic01LabelFormChat borderColorLabelVar">
                <span className="textBoldFormChat">{t("email.email2")} </span>
                {t("email.email3")}
              </Form.Label> */}
            <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
              {t("email.email2")}
              <span className="textBoldFormChat">{t("email.email3")} </span>
              {t("email.email4")}
            </Form.Label>
          </div>
          {/* )} */}
          <div className="divGenericInputFormChat">
            <div className="divInputFormChat">
              <ReactPhoneInput
                id="idInputPhoneNumberFormChat"
                className="divInputTelFormChat myCustomPhoneInput"
                inputExtraProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                required

                country={defaultPrefix}
                value={phone_budget}
                onChange={(e) => handleChangePrefix(e)}
              />

            </div>
          </div>
          <div className="divGenericInputFormChat me-0">
            <div className="divGenericInputFormChat">
              <div
                className={
                  (validPhone === "divTextFormChat" && "divTextFormChat") ||
                  (validPhone === "divTextFormChatRed" &&
                    "divTextFormChatRed") ||
                  (validPhone === "divTextFormChatGreen" &&
                    "divTextFormChatGreenBis")
                }
              >
                {validPhone === "divTextFormChat" ||
                  validPhone === "divTextFormChatRed" ? (
                  <>
                    <IconInfo />
                    <p className="marginTextPhone colorSpantVar">
                      {t("emailPiercing.exampleAbreviation")}+34 600 000 000
                    </p>
                  </>
                ) : (
                  <>
                    <IconCheckGreen />
                    <span className={`${validPhone} marginTextEmail`}>
                      {t("email.valid")}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </Form.Group>
      )}
    </>
  );
};
