import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { MessageLoading } from "../MessageLoading/MessageLoading";
import { IconCheckGreen } from "../Icons/Icon-check-green/IconCheckGreen";
import { IconInfo } from "../Icons/Icon-info/IconInfo ";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  nextComponent,
  backComponent,
  setFormComponent,
  clearFormData,
} from "../../utils/nextComponent";

export const EmailPhone = ({ block, props }) => {
  const [t] = useTranslation("global");

  const defaultPrefix =
    window.navigator.language.slice(0, 2) === "en"
      ? window.navigator.language.slice(3, 5).toLowerCase() ||
      "us"
      : window.navigator.language.slice(0, 2) === "es"
        ? window.navigator.language.slice(3, 5).toLowerCase() ||
        "es"
        : window.navigator.language.slice(0, 2) === "de"
          ? window.navigator.language.slice(3, 5).toLowerCase() ||
          "de"
          : window.navigator.language.slice(0, 2)

  const [messageLoading, setMessageLoading] = useState(true);
  const [email_budget, setEmail] = useState("");
  const [validMail, setValidMail] = useState("divTextFormChat");
  const [validPhone, setValidPhone] = useState("divTextFormChat");
  const [phone_budget, setPhone] = useState("");
  const myRef2 = useRef(null);

  useEffect(() => {
    if (block.conditional) {
      props.setShowWriting(true);
      const newFormData = [...props.formData, { email_budget: "" }];
      props.setForm(newFormData);
      setTimeout(() => {
        setMessageLoading(false);
        props.setShowWriting(false);
        executeScroll();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (
      validMail === "divTextFormChatGreen" &&
      validPhone === "divTextFormChatGreen"
    ) {
      nextComponent(props, block);
    } else {
      backComponent(props, block);
    }
  }, [validMail, validPhone]);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    const block2 = {
      _uid: "BUY6Drn2e9",
      component: "email_budget",
      headline: "email_budget",
      conditional: false,
    };

    setFormComponent(props, block2, { email_budget: e.target.value });

    let emailInput = e.target.value;
    if (emailInput.length === 1 || emailInput === "") {
      setValidMail("divTextFormChatRed");
      setValidPhone("divTextFormChatRed");
      setPhone(phone_budget.slice(0, 2));
      clearFormData(props, block2, { email_budget });
    }
    if (emailInput.includes("@") || emailInput.length !== 0) {
      let resultEmail = emailInput.split("@");

      if (resultEmail[0] !== "" && resultEmail[1]?.includes(".")) {
        let resultEmail1 = resultEmail[1].split(".");

        if (
          resultEmail1[0] !== "" &&
          resultEmail1[1].length >= 2 &&
          resultEmail1[1].length <= 7
        ) {
          setValidMail("divTextFormChatGreen");
        } else {
          setValidMail("divTextFormChatRed");
          setValidPhone("divTextFormChatRed");
          setPhone(phone_budget.slice(0, 2));
          clearFormData(props, block2, { email_budget });
        }
      }
    }
  };

  const handleChangePrefix = (e) => {
    const block2 = {
      _uid: "BUY6Drn2e9",
      component: "phone_budget",
      headline: "phone_budget",
      conditional: false,
    };

    let prefix = e.slice(0, 2);
    setPhone(e);
    setFormComponent(props, block2, { phone_budget: e });

    let inputPhone = e;
    if (inputPhone.length === 0) {
      setValidPhone("divTextFormChatRed");
      clearFormData(props, block2, { phone_budget });
    }

    if (e.length >= 3) {
      if (e.length >= 11) {
        setValidPhone("divTextFormChatGreen");
      } else if (e.length >= 10 && prefix !== "34") {
        setValidPhone("divTextFormChatGreen");
      } else {
        setValidPhone("divTextFormChatRed");
        clearFormData(props, block2, { phone_budget });
      }
    }
  };

  const executeScroll = () => {
    setTimeout(() => {
      myRef2?.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  return (
    <>
      {messageLoading === true ? (
        <MessageLoading />
      ) : (
        <Form.Group id="nombre" className="">
          <div className="divGenericLabelFormChatWelcome" ref={myRef2}>
            <Form.Label className="generic01LabelFormChat genericLabelFormChatBubble borderColorLabelVar backgroundColorContainer">
              {t("email.email2")}
              <span className="textBoldFormChat">{t("email.email3")} </span>
              {t("email.email4")}
            </Form.Label>
          </div>
          <div className="divGenericInputFormChat">
            <div className="divInputFormChat">
              <Form.Control
                autoComplete="nope"
                id="inputNameFormChat"
                className="inputNameFormChat inputEmailFormChat"
                type="email"
                placeholder={"Email"}
                onChange={handleChangeEmail}
                required
                autoFocus={true}
              />
            </div>
          </div>
          <div className="divGenericInputFormChat me-0">
            <div className="divGenericInputFormChat">
              <div
                className={
                  (validMail === "divTextFormChat" && "divTextFormChat") ||
                  (validMail === "divTextFormChatRed" &&
                    "divTextFormChatRed") ||
                  (validMail === "divTextFormChatGreen" &&
                    "divTextFormChatGreenBis")
                }
              >
                {validMail === "divTextFormChat" ||
                  validMail === "divTextFormChatRed" ? (
                  <>
                    <IconInfo />
                    <p className="marginTextPhone colorSpantVar">
                      {t("email.exampleAbreviation")}antonio@gmail.com
                    </p>
                  </>
                ) : (
                  <>
                    <IconCheckGreen />
                    <span className={`${validMail} marginTextEmail`}>
                      {t("email.valid")}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="divGenericInputFormChat">
            <div className="divInputFormChat">
              <ReactPhoneInput
                id="idInputPhoneNumberFormChat"
                className="divInputTelFormChat myCustomPhoneInput"
                inputExtraProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                required
                country={defaultPrefix}
                value={phone_budget}
                onChange={(e) => handleChangePrefix(e)}
                dropdownStyle={{
                  bottom: "100%",
                  transform: "translateY(10px)",
                }}
              />
            </div>
          </div>
          <div className="divGenericInputFormChat me-0">
            <div className="divGenericInputFormChat">
              <div
                className={
                  (validPhone === "divTextFormChat" && "divTextFormChat") ||
                  (validPhone === "divTextFormChatRed" &&
                    "divTextFormChatRed") ||
                  (validPhone === "divTextFormChatGreen" &&
                    "divTextFormChatGreenBis")
                }
              >
                {validPhone === "divTextFormChat" ||
                  validPhone === "divTextFormChatRed" ? (
                  <>
                    <IconInfo />
                    <p className="marginTextPhone colorSpantVar">
                      {t("emailPiercing.exampleAbreviation")}+34 600 000 000
                    </p>
                  </>
                ) : (
                  <>
                    <IconCheckGreen />
                    <span className={`${validPhone} marginTextEmail`}>
                      {t("email.valid")}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </Form.Group>
      )}
    </>
  );
};
